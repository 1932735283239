<template>
  <el-dialog
      :title="title"
      :visible.sync="open"
      :modal-append-to-body="true"
      :close-on-click-modal="false"
      :modal="false"
      top="60px"
      width="30%"
      custom-class="addClass"
      :destroy-on-close="true"
      :before-close="handleClose">

    <template #default >
      <div class="content">
        <ul class="classify-ul">
          <template v-for="(item,index) in list" >
            <template v-if="item.state === '0'" >
              <li :key="index">
                <div class="text">
                  <template v-if="item.is_edit" >
                    <el-input v-model="item.edit_classificationName" clearable placeholder="请输入"></el-input>
                  </template>
                  <template v-else>
                    <p>{{ item.classificationName }}</p>
                  </template>
                </div>
                <div class="but">
                  <template v-if="item.is_edit" >
                    <el-button type="text" size="small" @click="confirmEditFn(item)" >确认</el-button>
                    <el-button type="text" size="small" @click="cancelEditFn(item)" >取消</el-button>
                  </template>
                  <template v-else >
                    <el-button type="text" size="small" @click="editFn(item)" >编辑</el-button>
                    <el-button type="text" size="small" style="color: #f56c6c;" @click="delFn(item)" >删除</el-button>
                  </template>
                </div>
              </li>
            </template>
          </template>
          <li>
            <div class="text">
              <el-input v-model="add_params" clearable placeholder="请输入"></el-input>
            </div>
            <div class="but">
              <el-button type="text" size="small" @click="addFn(add_params)" >添加</el-button>
            </div>
          </li>
        </ul>
      </div>
    </template>

    <template #footer >
      <div class="but-class">
        <el-button size="small" @click="cancel">取 消</el-button>
        <el-button type="primary" size="small" @click="confirm">保 存</el-button>
      </div>
    </template>

  </el-dialog>
</template>

<script>
export default {
  name: "index",
  props: {
    value: {
      type: Boolean,
      default: () => false,
    }
  },
  data() {
    return {
      title: "维护分类",
      add_params: "",
      list: [],
    }
  },
  watch: {
    open: {
      handler(newVal) {
        if (newVal) {
          this.getVideoClassificationInfo();
        }
      },
      immediate: true,
    }
  },
  computed: {
    open: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input",value)
      }
    },
  },
  created() {

  },
  mounted() {

  },
  methods: {
    addFn(e) {
      if (!e) return this.$message({
        message: "添加内容不能为空",
        type: "error",
      })
      let obj = {
        videoClassificationInfoId: "",
        classificationName: e,
        edit_classificationName: e,
        state: '0',
        is_edit: false,
      }
      this.list.push(obj);
      this.add_params = "";
    },
    delFn(e) {
      e.state = '1';
    },
    editFn(e) {
      e.is_edit = true;
    },
    confirmEditFn(e) {
      e.classificationName = e.edit_classificationName;
      e.is_edit = false;
    },
    cancelEditFn(e) {
      e.edit_classificationName = e.classificationName;
      e.is_edit = false;
    },
    confirm() {
      this.$post("ec/editvideoclassificationinfo",this.list)
        .then(res => {
          if (!res) return false;
          // let data = res.data;
          this.$emit("change",false);
        })
    },
    cancel() {
      this.$emit("change",false);
    },
    handleClose(done) {
      done();
    },
    getVideoClassificationInfo() {
      // 所属分类
      this.$get("ec/getvideoclassificationinfo")
          .then(res => {
            if (!res) return false;
            let data = (res.data || []).map(item => {
              return {
                videoClassificationInfoId: item.videoClassificationInfoId,
                classificationName: item.classificationName,
                edit_classificationName: item.classificationName,
                state: '0',
                is_edit: false,
              }
            });
            this.list = data;
          })
    },
  }
}
</script>

<style lang="scss" scoped>
.content{
  .classify-ul{
    li{
      display: flex;
      align-items: center;
      height: 40px;
      .text{
        flex: 1;
        >p {
          line-height: 40px;
          padding: 0 16px;
        }
      }
      .but{
        width: 62px;
        margin-left: 10px;
      }
    }
    li + li{
      margin-top: 10px;
    }
  }
}
</style>
